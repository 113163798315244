import * as React from "react";
import { graphql } from 'gatsby';

// global components
import Layout from "../components/layout/Layout";

// styles

export const query = graphql`
  {
    craft {
      entry(section: "styleguide") {
        ... on Craft_styleguide_styleguide_Entry {
          title
          richTextStandard
        }
      }
    }
  }
`

const StyleguidePage = ({data}) => {
  const entry = data.craft.entry;

  return (
    <Layout>
      <div className={`small-page`}>
        <h1>{entry.title}</h1>

        <div className={`m-64`} dangerouslySetInnerHTML={{ __html: entry.richTextStandard }} />

      </div>
    </Layout>
  )
}

export default StyleguidePage
